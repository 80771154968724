
/* ---------------------------------- */
/* Magnific Popup
------------------------------------- */
.mfp-bg {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2000;
	overflow: hidden;
	position: fixed;
	background: #000;
	opacity: 0.96;
}

.mfp-wrap {
	top: -10px;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2001;
	position: fixed;
	outline: none !important;
	-webkit-backface-visibility: hidden;
}

.mfp-container {
	height: 100%;
	text-align: center;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	padding: 0 18px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.mfp-container:before {
	content: '';
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
	display: none;
}

.mfp-content {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	margin: 0 auto;
	text-align: left;
	z-index: 505;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
	width: 100%;
	cursor: auto;
}

.mfp-ajax-cur {
	cursor: progress;
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
	cursor: -moz-zoom-out;
	cursor: -webkit-zoom-out;
	cursor: zoom-out;
}

.mfp-zoom {
	cursor: pointer;
	cursor: -webkit-zoom-in;
	cursor: -moz-zoom-in;
	cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
	cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
 }

.mfp-loading.mfp-figure {
	display: none;
}

.mfp-hide {
	display: none !important;
}

.mfp-preloader {
	color: #aaa;
	position: absolute;
	top: 50%;
	width: auto;
	text-align: center;
	margin-top: -0.8em;
	left: 8px;
	right: 8px;
	z-index: 504;
}

.mfp-preloader a {
	color: #cccccc;
}

.mfp-preloader a:hover {
	color: #fff;
}

.mfp-s-ready .mfp-preloader {
	display: none;
}

.mfp-s-error .mfp-content {
	display: none;
}

.mfp-s-loading .mfp-arrow {
	display: none;
}

button.mfp-close,
button.mfp-arrow {
	overflow: visible;
	cursor: pointer;
	border: 0;
	-webkit-appearance: none;
	display: block;
	padding: 0;
	z-index: 506;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
	right: 0px;
	top: -40px;
}

/* Close Button */
.og-close,
button.mfp-close {
	position: absolute;
	width: 45px;
	height: 45px;
	top: -20px;
	display: block;
	right: -12px;
	cursor: pointer !important;
	z-index: 9999;
	color: #fff;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	border-radius: 50%;
	margin: 0px;
	background-color: transparent;
	outline: none;	transform: translate3d(0,0,0);
}

.mfp-iframe-holder .mfp-close {
	top: -50px;
	right: -12px;
	transform: translate3d(0,0,0);
}

.mfp-gallery .mfp-close {
	top: 20px;
	right: 10px;
}

.mfp-gallery  .mfp-content .mfp-close {
	display: none;
}

.mfp-gallery .mfp-close:after,
.mfp-gallery .mfp-close:before {
	font-family: "FontAwesome";
	font-size: 29px;
	top: 4px;
	left: 50%;
	position: absolute;
	height: 10px;
	transition: 0.3s;
}


.mfp-close::after,
.mfp-close::before {
    top: 0px;
}

.og-close {
	margin: 17px;
	right: 10px;
	background: transparent;
	color: #222;
}

.mfp-close:hover,
.og-close:hover {
	transform: rotate(90deg);
}

.og-close:after,
.og-close:before,
.mfp-close:after,
.mfp-close:before {
	font-family: Circular, -apple-system, BlinkMacSystemFont,
	Roboto, Helvetica Neue, sans-serif !important;
	font-size: 29px;
	top: 21px;
	left: 50%;
	position: absolute;
	height: 10px;
	transition: 0.3s;
}

.og-close:after,
.og-close:before { top: 7px; font-size: 29px; }

.og-close:after,
.mfp-close:after {
	content:"\f106";
	-webkit-transform: translate(0,0);
	transform: translate(-50%,60%);
}

.og-close:after {
	-webkit-transform: translate(0,0);
	transform: translate(-50%,60%);
}

.og-close:before,
.mfp-close:before {
	content:"\f107";
	-webkit-transform: translate(0,0);
	transform: translate(-50%,0%);
}

#small-dialog .mfp-close,
#sign-in-dialog, .mfp-close,
.mfp-close:hover {
	color: #fff;
}


/* Popup close button*/
#sign-in-dialog .mfp-close,
#small-dialog .mfp-close {
	color: #666;
	background-color: #e4e4e4;
	border-radius: 50%;
	top: 28px;
	right: 40px;
    width: 40px;
    height: 40px;
}

#sign-in-dialog .mfp-close:hover,
#small-dialog .mfp-close:hover {
	color: #fff;
	transform: rotate(0);
	background-color: #66676b;
}

#sign-in-dialog .mfp-close::after,
#sign-in-dialog .mfp-close::before,
#small-dialog .mfp-close::after,
#small-dialog .mfp-close::before {
	top: 4px;
	height: 9px;
	font-size: 21px;
	transition: 0s;
}

.small-dialog-content input[type=submit] {
	width: 100%;
	margin-top: 26px;
	margin-bottom: 10px;
}

.small-dialog-content .divider {
	display: inline-block;
	width: 100%;
	margin-top: 15px;
	padding-bottom: 0;
}

.small-dialog-header {
	font-size: 22px;
	width: calc(100% + 80px);
	position: relative;
	left: -40px;
	top: 0;
	border-radius: 4px 4px 0 0;
	display: inline-block;
	background-color: #f6f6f6;
	padding: 35px 40px;
	margin-bottom: 40px;
}

.small-dialog-header h3 {
	padding: 0;
	margin: 0;
	font-size: 24px;
}


.mfp-counter {
	position: absolute;
	top: 0;
	right: 0;
	color: #aaa;
	font-size: 13px;
	line-height: 18px;
}

.mfp-arrow {
	opacity: 1;
	margin: 0 20px;
	top: 50%;
	transform: translateY(-50%);
	z-index: 9999;
	color: #fff;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	cursor: pointer;
	width: 60px;
	height: 60px;
	position: absolute;
	display: block;
	z-index: 100;
	overflow: hidden;
	background: rgba(255,255,255,0.15);
	border-radius: 50%;
	outline: none;
}

.mfp-arrow:hover {
	background: #66676b;
	color: #fff;
}

.mfp-arrow.mfp-arrow-left,
.mfp-arrow.mfp-arrow-right {
	font-family: Circular, -apple-system, BlinkMacSystemFont,
  Roboto, Helvetica Neue, sans-serif !important;
	font-size: 20px;
}

.mfp-arrow.mfp-arrow-right { right: 15px; }

.mfp-arrow.mfp-arrow-left:before { content: "\e605"; left: -1px; top: 0px; position: relative; }
.mfp-arrow.mfp-arrow-right:before { content: "\e606"; left: 1px; top: 0px; position: relative; }

.mfp-content:hover .mfp-arrow {
	opacity: 1;
}

.mfp-iframe-holder {
	padding-top: 40px;
	padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
	line-height: 0;
	width: 100%;
	max-width: 1180px;
}

.mfp-iframe-scaler {
	width: 100%;
	height: 0;
	overflow: hidden;
	padding-top: 54%;
}

.mfp-iframe-scaler iframe {
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
	background: black;
}

/* Popup */
#sign-in-dialog,
#small-dialog {
	background: #fff;
	padding: 40px;
	padding-top: 0;
	text-align: left;
	max-width: 610px;
	margin: 40px auto;
	position: relative;
	box-sizing:border-box;
	border-radius: 4px;
}


#sign-in-dialog {
	max-width: 500px;
}


#sign-in-dialog span.line,
#small-dialog span.line {padding-top: 0;}

.my-mfp-zoom-in .zoom-anim-dialog {
	opacity: 0;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	-webkit-transform: scale(0.8);
	-moz-transform: scale(0.8);
	-ms-transform: scale(0.8);
	-o-transform: scale(0.8);
	transform: scale(0.8);
}

/* animate in */
.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
	opacity: 1;
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
}

/* animate out */
.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
	-webkit-transform: scale(0.8);
	-moz-transform: scale(0.8);
	-ms-transform: scale(0.8);
	-o-transform: scale(0.8);
	transform: scale(0.8);
	opacity: 0;
}

/* Dark overlay, start state */
.my-mfp-zoom-in.mfp-bg {
	opacity: 0.001; /* Chrome opacity transition bug */
	-webkit-transition: opacity 0.3s ease-out;
	-moz-transition: opacity 0.3s ease-out;
	-o-transition: opacity 0.3s ease-out;
	transition: opacity 0.3s ease-out;
}
/* animate in */
.my-mfp-zoom-in.mfp-ready.mfp-bg {
	opacity: 0.8;
}
/* animate out */
.my-mfp-zoom-in.mfp-removing.mfp-bg {
	opacity: 0;
}


/* Main image in popup */
img.mfp-img {
	width: auto;
	max-width: 100%;
	height: auto;
	display: block;
	line-height: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	margin: 40px auto;
	border-radius: 4px;
}

/* The shadow behind the image */
.mfp-figure:after {
	content: '';
	position: absolute;
	left: 0;
	top: 40px;
	bottom: 40px;
	display: block;
	right: 0;
	width: auto;
	height: auto;
	z-index: -1;
	box-shadow: 0 0 18px rgba(11,11,11, 0.6);
}

.mfp-figure {
	line-height: 0;
}

.mfp-bottom-bar {
	margin-top: -30px;
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	cursor: auto;
}

.mfp-title {
	text-align: left;
	line-height: 18px;
	color: #f3f3f3;
	word-break: break-word;
	padding-right: 36px;
}

.mfp-figure small {
	color: #bdbdbd;
	display: block;
	font-size: 12px;
	line-height: 14px;
}

.mfp-image-holder .mfp-content {
	max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
	cursor: pointer;
}


/* Media Queries for Magnific Popup */
@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {

  .mfp-img-mobile .mfp-image-holder {
	padding-left: 0;
	padding-right: 0;
   }

  .mfp-img-mobile img.mfp-img {
	padding: 0
   }

  /* The shadow behind the image */
  .mfp-img-mobile .mfp-figure:after {
	top: 0;
	bottom: 0;
   }

  .mfp-img-mobile .mfp-bottom-bar {
	background: rgba(0, 0, 0, 0.6);
	bottom: 0;
	margin: 0;
	top: auto;
	padding: 3px 5px;
	position: fixed;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
   }

  .mfp-img-mobile .mfp-bottom-bar:empty {
	padding: 0;
   }

  .mfp-img-mobile .mfp-counter {
	right: 5px;
	top: 3px;
   }

  .mfp-img-mobile .mfp-close {
	top: 0;
	right: 0;
	width: 35px;
	height: 35px;
	line-height: 35px;
	background: rgba(0, 0, 0, 0.6);
	position: fixed;
	text-align: center;
	padding: 0;
   }

  .mfp-img-mobile .mfp-figure small {
	display: inline;
	margin-left: 5px;
   }
}


.mfp-ie7 .mfp-img {
	padding: 0;
}

.mfp-ie7 .mfp-bottom-bar {
	width: 600px;
	left: 50%;
	margin-left: -300px;
	margin-top: 5px;
	padding-bottom: 5px;
}

.mfp-ie7 .mfp-container {
	padding: 0;
}

.mfp-ie7 .mfp-content {
	padding-top: 44px;
}

.mfp-ie7 .mfp-close {
	top: 0;
	right: 0;
	padding-top: 0;
}

.mfp-fade.mfp-bg {
	opacity: 0;
	-webkit-transition: all 0.2s ease-out;
	-moz-transition: all 0.2s ease-out;
	transition: all 0.2s ease-out;
}

.mfp-fade.mfp-bg.mfp-ready {
	opacity: 0.92;
	background-color: #111;
}

.mfp-fade.mfp-bg.mfp-removing {
	opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
	opacity: 0;
	-webkit-transition: all 0.2s ease-out;
	-moz-transition: all 0.2s ease-out;
	transition: all 0.2s ease-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
	opacity: 1;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
	opacity: 0;
}