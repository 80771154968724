/*
1. Bootstrap Basics
2. Bootstrap Components
3. Custom Style
4. Vendors
   4.1 Bootstrap Datepicker
   4.2 Headroom
   4.3 Uislider
5. Theme Style
   5.1 Header
   5.2 Inner Search
   5.3 List Style
   5.4 Admin
   5.5 Custom
   5.6 Footer


*/

// Core

@import "bootstrap/functions";
@import "custom/functions";

@import "custom/variables";
@import "bootstrap/variables";

@import "bootstrap/mixins";
// @import "custom/mixins";

@import "custom/mixins/alert.scss";
@import "custom/mixins/badge.scss";
@import "custom/mixins/background-variant.scss";
@import "custom/mixins/buttons.scss";
@import "custom/mixins/forms.scss";
@import "custom/mixins/icon.scss";
@import "custom/mixins/modals.scss";
@import "custom/mixins/popover.scss";

// Bootstrap components

@import "bootstrap/root";
@import "bootstrap/reboot";
@import "bootstrap/type";
@import "bootstrap/images";
@import "bootstrap/code";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";
@import "bootstrap/transitions";
@import "bootstrap/dropdown";
@import "bootstrap/button-group";
@import "bootstrap/input-group";
@import "bootstrap/custom-forms";
@import "bootstrap/nav";
@import "bootstrap/navbar";
@import "bootstrap/card";
@import "bootstrap/breadcrumb";
@import "bootstrap/pagination";
@import "bootstrap/badge";
@import "bootstrap/jumbotron";
@import "bootstrap/alert";
@import "bootstrap/progress";
@import "bootstrap/media";
@import "bootstrap/list-group";
@import "bootstrap/close";
@import "bootstrap/modal";
@import "bootstrap/tooltip";
@import "bootstrap/popover";
@import "bootstrap/carousel";
@import "bootstrap/utilities";
@import "bootstrap/print";

// Custom components


@import "custom/reboot.scss";
@import "custom/global.scss";
// @import "custom/utilities.scss";

@import "custom/utilities/backgrounds.scss";
@import "custom/utilities/floating.scss";
@import "custom/utilities/helper.scss";
@import "custom/utilities/position.scss";
@import "custom/utilities/sizing.scss";
@import "custom/utilities/spacing.scss";
@import "custom/utilities/shadows.scss";
@import "custom/utilities/text.scss";
@import "custom/utilities/transform.scss";

@import "custom/accordion.scss";
@import "custom/alerts.scss";
@import "custom/avatars.scss";
@import "custom/badge.scss";
@import "custom/buttons.scss";
@import "custom/card.scss";
@import "custom/carousel.scss";
@import "custom/close.scss";
@import "custom/custom-forms.scss";
@import "custom/dropdown.scss";
@import "custom/footer.scss";
@import "custom/forms.scss";
@import "custom/grid.scss";
@import "custom/icons.scss";
@import "custom/input-group.scss";
@import "custom/list-group.scss";
@import "custom/modal.scss";
@import "custom/nav.scss";
@import "custom/navbar.scss";
@import "custom/pagination.scss";
@import "custom/popover.scss";
@import "custom/progress.scss";
@import "custom/section.scss";
@import "custom/separator.scss";
@import "custom/type.scss";
@import "custom/block.scss";


// Vendor

@import "custom/vendor/bootstrap-datepicker.scss";
@import "custom/vendor/headroom.scss";
@import "custom/vendor/nouislider.scss";



@import "variables";

@import "footer";
@import "navigation";
@import "main-search";
@import "inner-container";
@import "map-banner";
@import "datepicker";

@import "pricing";
@import "pages/pages";
@import "third-party/third-party";
@import "component/component";

@import "custom/rtl.scss";

// regular style toast 
@import '~ngx-toastr/toastr';

// bootstrap style toast 
// or import a bootstrap 4 alert styled design (SASS ONLY) 
// should be after your bootstrap imports, it uses bs4 variables, mixins, functions 
@import '~ngx-toastr/toastr-bs4-alert';

// if you'd like to use it without importing all of bootstrap it requires 

.toast-error {
   background-color: adjust-hue($color: #BD362F, $degrees: 0);
   background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
}

.toast-success {
   background-color: adjust-hue($color: #51A351, $degrees: 0);
}

.toast-container {
   margin-top: 50px !important;
}


.toast-container .toast {
   color: #FFFFFF;
}

.pac-container {
   z-index: 9999;
}

.label_text {
   font-size: 15px;
   font-weight: 600;
   color: black;
}

.required-asterisk {
   color: red;
   font-weight: bold;
   margin-left: 3px;
   font-size: 1.2em;
}

.form-text {
   color: red !important;
}

.note-text {
   color: black;
}

.form-control {
   color: #111 !important;
}
.gmnoprint {
   position: absolute !important;
   top: 2vh !important;
   right: 50px !important;
}
.gmnoprint button {
   background-color: white !important;
   border: 1px solid #333 !important;
   border-radius: 5px !important;
}
.gm-svpc{
   display: none !important;
}
.gmnoprint span{
   display: none !important;
}
body .gmnoprint a{
   display: none !important;
}

body .gm-ui-hover-effect {
   position: absolute !important;
   right: 6px !important;
   top: 17px !important;
   background-color: transparent !important;
	background-image: url(../../assets/images/zyro-image.png) !important;
}

.gm-style-iw-d{
	overflow: hidden !important;
	max-height: 411px !important;
}

.sb-button{
   margin: 5px !important;
}

.sb-facebook{
   border-color: #79EBD2 !important;
   border-width: initial !important;
   background: white;
}

.sb-copy{
   border-color: #79EBD2 !important;
   border-width: initial !important;
   background: white;
}

.fa-facebook-f{
   color: #0E8CF1 !important;
}

@media (max-width: 1920px) {
   .container{
      max-width: 1500px;
   }
}

@media (max-width: 768px){
   .container{
      max-width: 100%;
      // display: flex;
      // justify-content: center;
      // align-items: center;
   }
   
   .winerydescription{
      min-width: 100%;
   }
   .bookingSummaryBookNow{
      padding-bottom: 45px !important;
   }
   #homepage-About{
      padding-top: 18rem !important;
      padding-bottom: 0rem !important;
   }
   .block-space {
      padding-top: 0rem !important;
      padding-bottom: 2rem !important;
  }
}

@media (max-width: 425px){
   .row .footer-line {
      margin-bottom: 1rem !important;
   }
   .footer-links {
      margin-top: 0rem !important;
   }
   .mb-4, .my-4{
      margin-bottom: 0.5rem !important;
   }
}