/*----------------------------------- */
/* Image Hovers
------------------------------------- */
.img-box {
	height: 350px;
	display: inline-block;
	position: relative;
	width: 100%;
	overflow: hidden;
	z-index: 90;
	margin: 10px 0;
	border-radius: 3px;
}

.img-box-background {
	background-size: cover;
	background-position: 50%;
	width: 100%;
	height: 100%;
	transition: all 0.55s;
	position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    transition: transform 0.35s ease-out;
}

.img-box:hover .img-box-background { transform: scale(1.07); }

.img-box:before {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: block;
	content: "";
	background-color: #222;
	opacity: 0.3;
	z-index: 99;
	transition: 0.3s ease-out;
}

.img-box-content {
	position: absolute;
	z-index: 101;
	top: 50%;
	margin: 0 auto;
	text-align: center;
	width: 100%;
	transform: translate(0,-50.5%);
}

.img-box-content h4 {
	font-size: 28px;
	line-height: 36px;
	color: #fff;
	margin: 0;
}

.img-box-content span {
	font-size: 16px;
	color: #fff;
}

