/* ---------------------------------- */
/* Post Navigation
------------------------------------- */
#posts-nav {
	padding: 0;
	list-style: none;
	width: 100%;
	position: relative;
	margin: 0;
	display: inline-block;
	min-height: 60px;
}

#posts-nav li span {
	display: block;
	color: #888;
	font-size: 15px;
	margin-bottom: 1px;
	font-weight: 400;
}

#posts-nav li {
	position: absolute;
	font-size: 17px;
	font-weight: 500;
	width: 50%;
}

#posts-nav li a {
	transition: transform 0.3s, color 0.3s 0s;
	color: #333;
	display: inline-block;
}

#posts-nav li a:hover { color: #66676b; }

#posts-nav li.next-post {
	right: 0;
	text-align: right;
}

#posts-nav li.prev-post {
	left: 0;
}

#posts-nav li.next-post a { padding-right: 50px; }
#posts-nav li.prev-post a { padding-left: 50px; }

#posts-nav li.prev-post a:before,
#posts-nav li.next-post a:before {
	font-family: "simple-line-icons";
	font-size: 20px;
	position: absolute;
	color: inherit;
	top: 50%;
	transform: translate3d(0,-50%,0);
	transition: transform 0.3s;
}

#posts-nav li.next-post a:before {
	right: 0;
	content: "\e606";
}

#posts-nav li.prev-post a:before {
	left: 0;
	content: "\e605"
}

#posts-nav li.next-post a:hover:before { transform: translate3d(5px,-50%,0); }
#posts-nav li.prev-post a:hover:before { transform: translate3d(-5px,-50%,0); }
