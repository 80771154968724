/* ---------------------------------- */
/* Slick Carousel
------------------------------------- */

/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;
    display: block;
}
.slick-track:before,
.slick-track:after
{
    display: table;
    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}

.slick-initialized .slick-slide
{
    display: block;
    // width: 500px !important;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}


/* Before slick carousel gets initialised*/
.logo-slick-carousel,
.testimonial-carousel,
.simple-fw-slick-carousel,
.simple-slick-carousel {
	display: none;
}

.slick-initialized.logo-slick-carousel,
.slick-initialized.testimonial-carousel,
.slick-initialized.simple-fw-slick-carousel,
.slick-initialized.simple-slick-carousel {
	display: block;
}

.simple-fw-slick-carousel .fw-carousel-item { padding: 10px 15px 0 15px; }
.simple-fw-slick-carousel {margin: 0 15px; max-width: 100%; box-sizing: border-box; }


/* Arrows */
.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    z-index: 100;
    display: block;
    width: auto;
    height: auto;
    padding: 0;
   
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}

.logo-slick-carousel .slick-prev,
.logo-slick-carousel .slick-next,
.simple-slick-carousel .slick-prev,
.simple-slick-carousel .slick-next { display: none !important; }

.simple-slick-carousel.arrows-nav .slick-prev,
.simple-slick-carousel.arrows-nav .slick-next { display: block !important; }

.slick-prev:hover,
.slick-next:hover {
    color: transparent;
    outline: none;
    background: transparent;
}

.slick-prev:hover:before,
.slick-next:hover:before {
    color: #999;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: .25;
}

.slick-prev:before,
.slick-next:before {
    font-family: 'simple-line-icons';
    font-size: 32px;
    line-height: 1;
    opacity: 1;
    color: #aaa;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: all 0.3s;
}

.white-nav .slick-prev:before,
.white-nav .slick-next:before {
    color: #fff;
}

.slick-prev { left: 3px; transform: translate3d(-90px,-50%,0); }
.slick-next { right: 3px; transform: translate3d(90px,-50%,0); }

.slick-prev:before { font-family:'FontAwesome'; content:"\f060" }
.slick-next:before {font-family:'FontAwesome'; content:"\f061" }


/* Dots */
.slick-dotted.slick-slider { margin-bottom: 30px; }

.slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.simple-slick-carousel.slick-dotted.slick-slider { margin-bottom: 0; }
.simple-slick-carousel.slick-dotted.slick-slider.dots-nav { margin-bottom: 30px; }

.simple-slick-carousel .slick-dots { display: none !important; }
.simple-slick-carousel.dots-nav .slick-dots { display: block !important; }

.slick-dots li {
    position: relative;
    display: inline-block;
    padding: 0;
    margin: 0 3px;
    cursor: pointer;
}

.slick-dots li {
    font-size: 0;
    line-height: 0;
    cursor: pointer;
    color: transparent;
    border: 0;
    position: relative;
    outline: none;
	display: inline-block;
	width: 8px;
	height: 8px;
	margin: 0 3px;
	opacity: 1;
	border-radius: 50%;
	line-height: 0;
	box-shadow: inset 0 0 0 2px #c0c0c0;
	transition: all 0.3s;
}

.slick-dots li.slick-active {
	box-shadow: inset 0 0 0 6px #c0c0c0;
	transition: all 0.3s;
}

.slick-dots li:after {
    display: block;
    height: 7px;
    width: 7px;
	content: "";
	position: absolute;
	top: 1px;
	left: 1px;
	background-color: transparent;
	border-radius: 50%;
	transition: all 0.3s;
	opacity: 0;
	background-color: #c0c0c0;
}

.slick-dots li.slick-active:after {
	opacity: 1;
}


.white-nav .slick-dots li { box-shadow: inset 0 0 0 2px #fff; }
.white-nav .slick-dots li.slick-active { box-shadow: inset 0 0 0 8px #fff; }

.slick-dots li button { display: none;}

.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none;
}


/* Logo Carousel */
.logo-slick-carousel div.item {
	margin-bottom: 30px;
	text-align: center;
	display: inline-block;
}

.logo-slick-carousel div.item img { display: inline-block; }

/* Fullwidth Slick Carousel */
.fullwidth-slick-carousel .slick-slide {
	margin: 0 15px 30px 15px;
	
	transition: 0.4s;
	pointer-events: none;
}

.slick-slide { outline: none; }

.testimonial-carousel .slick-slide.slick-active,
.fullwidth-slick-carousel .slick-slide.slick-active {
	opacity: 1;
	filter: none;
	pointer-events: all;
}

.fullwidth-carousel-container {
	overflow: hidden;
	width: 100%;
}

.category-box-container {
	position: relative;
	height: 100%;
}

.category-box-container.half {
	height: calc(50% - 15px);
	margin-bottom: 30px;
}

.category-box {
	background: #ccc;
   border-radius: 4px;

   height: 100%;
   
   display: flex;
   
   margin-bottom: 20px;
   
   position: relative;
   
   background-size: cover;
   
   background-repeat: no-repeat;
   
   background-position: 50%;
   
   overflow: hidden;
   
   
   
   justify-content: center;
   
   align-content: center;
   
   align-items: center;
}

 body .category-box{
    &:before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 9;
        top: 0;
       left: 0;
       bottom: 0;
       opacity: 0.6;
       right: 0;
       transition: all 0.3s ease-in-out 0s;
       background-image: linear-gradient(to right, #434343 0%, black 100%);
 background-blend-mode: multiply,multiply;
       
    }
    &:hover{
        &:before {
           opacity: 0.9;
           background:linear-gradient(-20deg, rgb(94,114,228) 0%, rgb(33, 212, 253) 100%);
        }
    }
 }



.fullwidth-slick-carousel.category-carousel .slick-slide {
	height: 300px;
}

.fullwidth-slick-carousel.category-carousel .slick-slide.fw-carousel-review {
	height: auto;
}

.category-box-content {
	position: absolute;

	z-index: 50;
	box-sizing: border-box;
}

.icon-title{
margin-bottom: 1rem;
}

.category-box-content h3 {
	color: #fff;
	font-size: 1.3rem;
	padding: 5px 0;
	margin: 0;
}

.category-box-content span {
	font-size: 18px;
	font-weight: 300;
	display: inline-block;
	color: rgba(255,255,255,0.7);
}

.category-box-content .icon-title {

}

.category-box-content .fa {
	
	color:#fff;
}

// .category-box-btn {
// 	position: absolute;
// 	right: 32px;
// 	bottom: 32px;
// 	z-index: 111;
// 	background-color: transparent;
// 	border: 1px solid #fff;
// 	color: #fff;
// 	padding: 8px 0px;
// 	text-align: center;
// 	min-width: 120px;
// 	border-radius: 50px;
// 	transition: all 0.3s;
// }

.category-box-btn:hover {
	background-color: #66676b;
	border-color: #66676b;
}

.fw-carousel-item {
	position: relative;
	height: 100%;
}

.category-box-background {
	width: 100%;
	height: 100%;
	position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    transition: transform 0.35s ease-out;
	transform: translate3d(0,0,0) scale(1);
	image-rendering: -webkit-optimize-contrast;
}

.category-box:hover .category-box-background { transform: translate3d(0,0,0) scale(1.08); }


/* Simple Slick Carousel */
.simple-slick-carousel {
	height: auto;
	width: calc(100% + 30px);
	left: -10px;
}

.simple-slick-carousel .category-box-container {
	height: 100%;
	display: block;
}

.simple-slick-carousel .slick-slide {
	padding: 10px 15px;
}


/* Masonry Filtering Styles*/
.option-set { padding: 0; margin: 0; }
.option-set li { display: inline-block; margin-right: 2px; }

.option-set li a {
	background: none;
	padding: 5px 16px;
	display: block;
	outline: none;
	border: none;
	font-weight: 600;
	float: right;
	margin: 0;
	font-size: 15px;
	border-radius: 50px;
	background-color: #f0f0f0;
	color: #666;
	line-height: 26px;
	transition: all 0.3s;
}

.option-set li a:hover { background-color: #e9e9e9; }
.option-set li a.selected {
	color: #fff;
	background-color: #66676b;
}


.mostviewed-bg{
    background-size: cover;
    height: 267px;
}
.list-logo {
	width: 90px;

height: 90px;

margin: 0 auto;

position: absolute;

left: 0;

right: 0;

top: -45px;
}
.font-aw{
   li{
       text-align: center;
       i{
           font-size: 22px;
       }
       margin-bottom: 2rem;
       margin-top: 1rem;
       span{
           display: block;
       }
   }
}

.slick-slide .item{
  height: 400px;
  display: block;
}
.slick-prev{
  left: 10px;
  transform: none;
  }
.slick-next{
right: 10px;
transform: none;
  }

  .listing-details-sidebar {
	list-style: none;
   padding: 0;
    li {
      margin-bottom: 0.2rem;
       i {
         margin-right: 0.5rem;
      }
   }
}

list-detail-one{
   .slick-slide .item {
      height: 400px;
      display: block;
      background-size: cover;
      background-position: center center;
   }
}

.h-100-vh{
   height: 100vh;
}

most-visited-places .list-logo img {
	background: #fff;
	border-radius: 100%;
}
.num-rate {
	font-size: 0.8rem;
}


gallery-slider .slick-arrow {
	background: #fff;
	width: 50px;
	height: 50px;
   border-radius: 6px;
   &.slick-prev::before, &.slick-next::before{
      font-size: 22px;
   }
   &:hover{
      background: #fff;
   }
}
