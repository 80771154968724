

/* ---------------------------------- */
/* Tooltips
------------------------------------- */
#tiptip_holder {
	display: none;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 99999;
}

#tiptip_holder.tip_top {
	padding-bottom: 5px;
	-webkit-transform: translateX(12px)
}

#tiptip_holder.tip_bottom { padding-top: 5px; }
#tiptip_holder.tip_right { padding-left: 5px; }
#tiptip_holder.tip_left { padding-right: 5px; }

#tiptip_content {
	color: #fff;
	font-size: 13px;
	line-height: 16px;
	padding: 6px 10px;
	background-color: #333;
	border-radius: 3px;
}

#tiptip_arrow, #tiptip_arrow_inner {
	position: absolute;
	border-color: transparent;
	border-style: solid;
	border-width: 6px;
	height: 0;
	width: 0;
}

#tiptip_holder.tip_top #tiptip_arrow { border-top-color: #333; }
#tiptip_holder.tip_bottom #tiptip_arrow { border-bottom-color: #333; }
#tiptip_holder.tip_right #tiptip_arrow { border-right-color:#333; }
#tiptip_holder.tip_left #tiptip_arrow { border-left-color: #333; }

#tiptip_holder.tip_top #tiptip_arrow_inner {
	margin-top: -7px;
	margin-left: -6px;
	border-top-color: #333;
}

#tiptip_holder.tip_bottom #tiptip_arrow_inner {
	margin-top: -5px;
	margin-left: -6px;
	border-bottom-color: #333;
}

#tiptip_holder.tip_right #tiptip_arrow_inner {
	margin-top: -6px;
	margin-left: -5px;
	border-right-color: #333;
}

#tiptip_holder.tip_left #tiptip_arrow_inner {
	margin-top: -6px;
	margin-left: -7px;
	border-left-color: #333;
}
