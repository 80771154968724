/* ---------------------------------- */
/* Info Box
------------------------------------- */
.info-box {
	background-color: #fff;
	display: inline-block;
	width: 100%;
	border-radius: 3px;
	padding: 23px 27px;
	border-top: 2px solid #66676b;
	background: linear-gradient(to bottom, rgba(255,255,255,0.98), rgba(255,255,255,0.95));
	background-color: #66676b;
	color: #66676b;
}

.info-box.no-border {
	border: none;
	background: linear-gradient(to bottom, rgba(255,255,255,0.96), rgba(255,255,255,0.93));
	background-color: #66676b;
}

.info-box h4 {
	margin: 0;
	font-size: 19px;
	color: #66676b;
	margin-bottom: 20px;
	margin-top: 0px;
}

.info-box p {
	margin-bottom: 0;
	font-size: 15px;
	line-height: 26px;
}
.infoBox {
	padding: 0px !important;
}

// .info-box.large {
// 	padding: 36px;
// }

.infoBox .star-rating {
    font-size: 16px;
    margin-top: 1px;
}

.infoBox .star-rating span { margin-right: 3px; }

.infoBox .rating-counter {
	position: relative;
	top: -1px;
}

// .infoBox:after {
// 	content: "";
// 	position: absolute;
// 	bottom: 0px;
// 	left: 50%;
// 	transform: translateX(-50%);
// 	width: 0;
// 	height: 0;
// 	border-left: 20px solid transparent;
// 	border-right: 20px solid transparent;
// 	border-top: 20px solid #fff;
// }