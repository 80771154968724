/* Like Icon */
span.like-icon {
	color: #fff;
	font-size: 19px;
	position: absolute;
	z-index: 101;
	right: 30px;
	bottom: 30px;
	cursor: normal;
	background-color: rgba(24,24,24,0.4);
	display: block;
	height: 44px;
	width: 44px;
	border-radius: 50%;
	transition: all 0.4s;
}

.like-icon:before,
.like-icon:after {
	transition: opacity 0.3s, transform 0.4s cubic-bezier(.0,-.41,.19,2.5);
	font-family: "FontAwesome";
	position: absolute;
	right: 0;
	left: 0;
	text-align: center;
	top: 9px;
}

.like-icon:before {
	content: "\f08a";
	transform: scale(0.95);
}

.like-icon:after {
	content: "\f004";
	opacity: 0;
	transform: scale(0.5);
}

.like-icon.liked:after {
	opacity: 1;
	transform: scale(0.95);
}

.like-icon:hover,
.like-icon.liked {
	background-color: #f3103c;
}

.like-icon.liked:before{
	opacity: 0;
}