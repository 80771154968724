/*----------------------------------- */
/* Custom Checkboxes
------------------------------------- */
.checkboxes {
	list-style: none;
	padding: 0;
}

.checkboxes.in-row label { margin-top: 9px;}
.checkboxes.one-in-row label { margin-top: 9px; width: 100%; }

.checkboxes li { padding: 4px 0; }
.checkboxes li:last-child { margin-bottom: -8px; }

.checkboxes label {
	display: inline-block;
	cursor: pointer;
	position: relative;
	padding-left: 28px;
	margin-right: 20px;
	margin-bottom: 0;
	line-height: 24px;
	font-size: 16px;
}

.checkboxes label span {
	color: #909090;
}

.checkboxes input[type=checkbox] { display: none; }

.checkboxes label:before {
	content: "";
	display: inline-block;
	width: 19px;
	height: 19px;
	margin-right: 10px;
	position: absolute;
	left: 0;
	top: 3px;
	background-color: #fff;
	border: 2px solid #d0d0d0;
	border-radius: 4px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	transition: all 0.25s;
	font-family: "FontAwesome";
	font-size: 12px;
	color: #fff;
	text-align: center;
	line-height: 15px;
}

.checkboxes input[type=checkbox]:checked + label:before {
	content: "\f00c";
	background-color: #66676b;
	border: 2px solid #66676b;
}


