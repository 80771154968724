/* ---------------------------------- */
/* Social Icons
------------------------------------- */
.social-icons { padding-left: 5px; }

.social-icons li {
	display: inline;
	float: left;
	text-align: left;
	margin-right: 16px;
	margin-top: 11px;
	transform:scale(1.19);
	-webkit-transform:scale(1.19);
	-webkit-filter: blur(radius);
}

.social-icons li a {
	width: 44px;
	height: 44px;
	display: inline-block;
	background-color: #f3f3f3;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	position: relative;
	overflow: hidden;
	border: 1px solid transparent;
}

.social-icons.fixed li a {
	display: block;
}

/* Common Styles */
.social-icons li a i {
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	position: absolute;
	z-index: 20;
	transform:scale(0.9);
	-webkit-transform:scale(0.9);
	-webkit-filter: blur(radius);
}

.social-icons li a:hover,
.social-icons li a i {
	color: #fff;
}

.social-icons li a i {
	top: -30px;
	opacity:0;
}

.social-icons li a:hover i {
	top: 0;
	opacity: 1;
}


/* Rounded Style */
.social-icons.rounded li a { border-radius: 50%; }


/* Border Style */
.social-icons.border li a {
	background-color: #fff;
	border: 1px solid #e0e0e0;
}

.social-icons.border li a:hover {
	border-color: transparent;
}

/* Color Style */
.social-icons.color li a {
	border-color: transparent;
}
.social-icons.color li a i,
.social-icons.color li a:hover i {
	top: 0;
	opacity: 1;
}

.social-icons.color a:before { opacity: 0; }

/* Fixed Style */
.social-icons.fixed {
	position: fixed;
	left: 0;
	padding-left: 2px;
	top: 100px;
	width: 50px;
}

.social-icons.fixed li {
	transform:scale(1.15);
	-webkit-transform:scale(1.15);
	margin-top: 7px;
}

.social-icons.fixed li a { background-color: rgba(51,51,51,0.35); }
.social-icons.fixed li a:before { color: #fff; }

.twitter:before,
.wordpress:before,
.facebook:before,
.linkedin:before,
.steam:before,
.tumblr:before,
.github:before,
.delicious:before,
.instagram:before,
.xing:before,
.amazon:before,
.dropbox:before,
.paypal:before,
.lastfm:before,
.gplus:before,
.yahoo:before,
.pinterest:before,
.dribbble:before,
.flickr:before,
.reddit:before,
.vimeo:before,
.spotify:before,
.rss:before,
.youtube:before,
.blogger:before,
.appstore:before,
.digg:before,
.evernote:before,
.fivehundredpx:before,
.forrst:before,
.stumbleupon:before,
.dribbble:before {
	font-family: "fontello";
	font-size: 16px;
	line-height: 16px;
	color: #a0a0a0;
	-webkit-transition: all 0.15s ease-in-out;
	-moz-transition: all 0.15s ease-in-out;
	-o-transition: all 0.15s ease-in-out;
	-ms-transition: all 0.15s ease-in-out;
	transition: all 0.15s ease-in-out;
	position: absolute;
	z-index: 20;
	transform:scale(0.9);
}

/* Icon Styles */
.twitter:before,
.social-icons li a.twitter i {font-size: 14px ;}
.twitter i, .twitter:before { margin: 13px 0 0 12px;  }
.twitter:before { content: "\54"; top: 0; opacity:1; }
.twitter:hover:before { top: 30px; opacity: 0; }
.twitter:hover { background-color: #3bc1ed !important; }
.twitter:hover,
.social-icons.color  .twitter,
.social-icons.fixed .twitter:hover { background-color: #3bc1ed; border-color: #3bc1ed; }

.dribbble i, .dribbble:before { margin: 13px 0 0 13px; }
.dribbble:before { content: "\44"; top: 0; opacity:1; }
.dribbble:hover:before { top: 30px; opacity: 0; }
.dribbble:hover { background-color: #ef5a92 !important; }
.social-icons.color .dribbble,
.social-icons.fixed .dribbble:hover { background-color: #ef5a92 ; border-color: #ef5a92 ; }

.facebook i, .facebook:before { margin: 13px 0 0 16px; }
.facebook:before { content: "\66"; top: 0; opacity:1; }
.facebook:hover:before { top: 30px; opacity: 0; }
.facebook:hover { background-color: #4a6d9d !important; }
.social-icons.color .facebook,
.social-icons.fixed .facebook:hover { background-color: #4a6d9d ; border-color: #4a6d9d ;}

.linkedin i, .linkedin:before { margin: 13px 0 0 13px; }
.linkedin:before { content: "\4c"; top: 0; opacity:1; }
.linkedin:hover:before { top: 30px; opacity: 0; }
.linkedin:hover { background-color: #0b7bb5 !important; }
.social-icons.color .linkedin,
.social-icons.fixed .linkedin:hover { background-color: #0b7bb5 ; border-color: #0b7bb5 ;}

.delicious i, .delicious:before { margin: 13px 0 0 13px; }
.delicious:before { content: "\23"; top: 0; opacity:1; }
.delicious:hover:before { top: 30px; opacity: 0; }
.delicious:hover { background-color: #3274d1 !important ; }
.social-icons.color .delicious,
.social-icons.fixed .delicious:hover { background-color: #3274d1 ; border-color: #3274d1 ;}

.paypal i, .paypal:before { margin: 13px 0 0 13px; }
.paypal:before { content: "\24"; top: 0; opacity:1; }
.paypal:hover:before { top: 30px; opacity: 0; }
.paypal:hover { background-color: #2e689a !important ; }
.social-icons.color .paypal,
.social-icons.fixed .paypal:hover { background-color: #2e689a ; border-color:#2e689a  ;}

.gplus i, .gplus:before { margin: 13px 0 0 13px; }
.gplus:before { content: "\f0d5"; top: 0; opacity:1; }
.gplus:hover:before { top: 30px; opacity: 0; }
.gplus:hover { background-color: #d53824 !important ; }
.social-icons.color .gplus,
.social-icons.fixed .gplus:hover { background-color: #d53824 ;border-color: #d53824 ;}

.stumbleupon i, .stumbleupon:before { margin: 13px 0 0 11px; }
.stumbleupon:before { content: "\2f"; top: 0; opacity:1; }
.stumbleupon:hover:before { top: 30px; opacity: 0; }
.stumbleupon:hover { background-color: #eb4924 !important ; }
.social-icons.color .stumbleupon,
.social-icons.fixed .stumbleupon:hover { background-color: #eb4924 ; border-color:#eb4924  ;}

.fivehundredpx i, .fivehundredpx:before { margin: 13px 0 0 11px; }
.fivehundredpx:before { content: "\30"; top: 0; opacity:1; }
.fivehundredpx:hover:before { top: 30px; opacity: 0; }
.fivehundredpx:hover { background-color: #444 !important ; }
.social-icons.color .fivehundredpx,
.social-icons.fixed .fivehundredpx:hover { background-color: #444 ; border-color:  #444 ;}

.pinterest i, .pinterest:before { margin: 13px 0 0 15px; }
.pinterest:before { content: "\31"; top: 0; opacity:1; }
.pinterest:hover:before { top: 30px; opacity: 0; }
.pinterest:hover { background-color: #cb1f25 !important ; }
.social-icons.color .pinterest,
.social-icons.fixed .pinterest:hover { background-color: #cb1f25 ; border-color: #cb1f25 ;}

.forrst i, .forrst:before { margin: 13px 0 0 15px; }
.forrst:before { content: "\3a"; top: 0; opacity:1; }
.forrst:hover:before { top: 30px; opacity: 0; }
.forrst:hover { background-color: #40833e !important ; }
.social-icons.color .forrst,
.social-icons.fixed .forrst:hover { background-color: #40833e ; border-color:  #40833e ;}

.digg i, .digg:before { margin: 13px 0 0 12px; }
.digg:before { content: "\3b"; top: 0; opacity:1; }
.digg:hover:before { top: 30px; opacity: 0; }
.digg:hover { background-color: #205685 !important ; }
.social-icons.color .digg,
.social-icons.fixed .digg:hover { background-color: #205685 ; border-color: #205685 ;}

.spotify i, .spotify:before { margin: 13px 0 0 13px; }
.spotify:before { content: "\3d"; top: 0; opacity:1; }
.spotify:hover:before { top: 30px; opacity: 0; }
.spotify:hover { background-color: #6ca405 !important ; }
.social-icons.color .spotify,
.social-icons.fixed .spotify:hover { background-color: #6ca405 ; border-color: #6ca405 ;}

.reddit i, .reddit:before { margin: 13px 0 0 12px; }
.reddit:before { content: "\3e"; top: 0; opacity:1; }
.reddit:hover:before { top: 30px; opacity: 0; }
.reddit:hover { background-color: #404040 !important ; }
.social-icons.color .reddit,
.social-icons.fixed .reddit:hover { background-color: #404040 ; border-color:  #404040 ;}

.appstore i, .appstore:before { margin: 13px 0 0 14px; }
.appstore:before { content: "\41"; top: 0; opacity:1; }
.appstore:hover:before { top: 30px; opacity: 0; }
.appstore:hover { background-color: #c0c0c0 !important ; }
.social-icons.color .appstore,
.social-icons.fixed .appstore:hover { background-color: #c0c0c0 ; border-color: #c0c0c0 ;}

.blogger i, .blogger:before { margin: 13px 0 0 13px; font-size: 14px ; }
.blogger:before { content: "\42"; top: 0; opacity:1; }
.blogger:hover:before { top: 30px; opacity: 0; }
.blogger:hover { background-color: #ff6501 !important ; }
.social-icons.color .blogger,
.social-icons.fixed .blogger:hover { background-color: #ff6501 ; border-color:#ff6501  ;}

.dribbble i, .dribbble:before { margin: 13px 0 0 13px; }
.dribbble:before { content: "\44"; top: 0; opacity:1; }
.dribbble:hover:before { top: 30px; opacity: 0; }
.dribbble:hover { background-color: #ef5a92 !important ; }
.social-icons.color .dribbble,
.social-icons.fixed .dribbble:hover { background-color: #ef5a92 ; border-color: #ef5a92 ;}

.evernote i, .evernote:before { margin: 13px 0 0 15px; }
.evernote:before { content: "\45"; top: 0; opacity:1; }
.evernote:hover:before { top: 30px; opacity: 0; }
.evernote:hover { background-color: #6bb130  !important; }
.social-icons.color .evernote,
.social-icons.fixed .evernote:hover { background-color: #6bb130 ; border-color: #6bb130 ;}

.flickr i, .flickr:before { margin: 13px 0 0 13px; }
.flickr:before { content: "\46"; top: 0; opacity:1; }
.flickr:hover:before { top: 30px; opacity: 0; }
.flickr:hover { background-color: #136dd5  !important; }
.social-icons.color .flickr,
.social-icons.fixed .flickr:hover { background-color: #136dd5 ;border-color: #136dd5 ; }

.rss i, .rss:before { margin: 13px 0 0 14px; font-size: 14px ; }
.rss:before { content: "\52"; top: 0; opacity:1; }
.rss:hover:before { top: 30px; opacity: 0; }
.rss:hover { background-color: #fe8f00  !important; }
.social-icons.color .rss,
.social-icons.fixed .rss:hover { background-color: #fe8f00 ; border-color: #fe8f00 ;}

.youtube i, .youtube:before { margin: 13px 0 0 14px; font-size: 18px ; }
.youtube:before { content: "\55"; top: 0; opacity:1; }
.youtube:hover:before { top: 30px; opacity: 0; }
.youtube:hover { background-color: #bf2f29  !important; }
.social-icons.color .youtube,
.social-icons.fixed .youtube:hover { background-color: #bf2f29 ;border-color: #bf2f29 ; }

.vimeo i, .vimeo:before { margin: 13px 0 0 13px; font-size: 14px ; }
.vimeo:before { content: "\56"; top: 0; opacity:1; }
.vimeo:hover:before { top: 30px; opacity: 0; }
.vimeo:hover { background-color: #4c8ab0  !important; }
.social-icons.color .vimeo,
.social-icons.fixed .vimeo:hover { background-color: #4c8ab0 ; border-color: #4c8ab0 ;}

.xing i, .xing:before { margin: 13px 0 0 14px; }
.xing:before { content: "\58"; top: 0; opacity:1; }
.xing:hover:before { top: 30px; opacity: 0; }
.xing:hover { background-color: #036568 !important ; }
.social-icons.color .xing,
.social-icons.fixed .xing:hover { background-color: #036568 ; border-color: #036568 ;}

.yahoo i, .yahoo:before { margin: 13px 0 0 11px; }
.yahoo:before { content: "\59"; top: 0; opacity:1; }
.yahoo:hover:before { top: 30px; opacity: 0; }
.yahoo:hover { background-color: #9251a2  !important; }
.social-icons.color .yahoo,
.social-icons.fixed .yahoo:hover { background-color: #9251a2 ; border-color: #9251a2 ;}

.amazon i, .amazon:before { margin: 13px 0 0 12px; }
.amazon:before { content: "\61"; top: 0; opacity:1; }
.amazon:hover:before { top: 30px; opacity: 0; }
.amazon:hover { background-color: #2f3842  !important; }
.social-icons.color .amazon,
.social-icons.fixed .amazon:hover { background-color: #2f3842 ; border-color: #2f3842  ;}

.steam i, .steam:before { margin: 13px 0 0 11px; }
.steam:before { content: "\62"; top: 0; opacity:1; }
.steam:hover:before { top: 30px; opacity: 0; }
.steam:hover { background-color: #383838 !important ; }
.social-icons.color .steam,
.social-icons.fixed .steam:hover { background-color: #383838 ; border-color:  #383838 ;}

.dropbox i, .dropbox:before { margin: 13px 0 0 13px; }
.dropbox:before { content: "\64"; top: 0; opacity:1; }
.dropbox:hover:before { top: 30px; opacity: 0; }
.dropbox:hover { background-color: #2c92dd !important ; }
.social-icons.color .dropbox,
.social-icons.fixed .dropbox:hover { background-color: #2c92dd ;border-color:#2c92dd  ; }

.github i, .github:before { margin: 13px 0 0 13px; }
.github:before { content: "\67"; top: 0; opacity:1; }
.github:hover:before { top: 30px; opacity: 0; }
.github:hover { background-color: #5c7381  !important; }
.social-icons.color .github,
.social-icons.fixed .github:hover { background-color: #5c7381 ;border-color: #5c7381 ; }

.lastfm i, .lastfm:before { margin: 13px 0 0 11px; }
.lastfm:before { content: "\6c"; top: 0; opacity:1; }
.lastfm:hover:before { top: 30px; opacity: 0; }
.lastfm:hover { background-color: #d21309 !important ; }
.social-icons.color .lastfm,
.social-icons.fixed .lastfm:hover { background-color: #d21309 ;border-color: #d21309 ; }

.tumblr i, .tumblr:before { margin: 13px 0 0 15px; }
.tumblr:before { content: "\74"; top: 0; opacity:1; }
.tumblr:hover:before { top: 30px; opacity: 0; }
.tumblr:hover { background-color: #3d658d !important ; }
.social-icons.color .tumblr,
.social-icons.fixed .tumblr:hover { background-color: #3d658d ; border-color: #3d658d ; }

.wordpress i, .wordpress:before { margin: 13px 0 0 14px; }
.wordpress:before { content: "\77"; top: 0; opacity:1; }
.wordpress:hover:before { top: 30px; opacity: 0; }
.wordpress:hover { background-color: #383838  !important; }
.social-icons.color .wordpress,
.social-icons.fixed .wordpress:hover { background-color: #383838 ; border-color: #383838 ;}

.instagram i, .instagram:before { margin: 13px 0 0 13px; }
.instagram:before { content: "\f31f"; top: 0; opacity:1; }
.instagram:hover:before { top: 30px; opacity: 0; }
.instagram:hover { background-color: #3f6f94  !important; }
.social-icons.color .instagram,
.social-icons.fixed .instagram:hover { background-color: #3f6f94 ;border-color:#3f6f94  ; }


/* Web Font */
@font-face {
  font-family: 'fontello';
  src: url('../../fonts/fontello.eot');
  src: url('../../fonts/fontello.eot') format('embedded-opentype'),
	   url('../../fonts/fontello.woff') format('woff'),
	   url('../../fonts/fontello.ttf') format('truetype'),
	   url('../../fonts/fontello.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

.social-icons li i {
	font-family: "fontello";
	font-style: normal;
	font-weight: normal;
	speak: none;
	display: inline-block;
	text-decoration: inherit;
	text-align: center;
	font-variant: normal;
	text-transform: none;
	line-height: 16px;
	font-size: 16px;
	width: auto;
}

.icon-delicious:before { content: '\23'; } /* '#' */
.icon-paypal:before { content: '\24'; } /* '$' */
.icon-gplus:before { content: '\f0d5'; } /* '+' */
.icon-stumbleupon:before { content: '\2f'; } /* '/' */
.icon-fivehundredpx:before { content: '\30'; } /* '0' */
.icon-pinterest:before { content: '\31'; } /* '1' */
.icon-forrst:before { content: '\3a'; } /* ':' */
.icon-digg:before { content: '\3b'; } /* ';' */
.icon-spotify:before { content: '\3d'; } /* '=' */
.icon-reddit:before { content: '\3e'; } /* '&gt;' */
.icon-appstore:before { content: '\41'; } /* 'A' */
.icon-blogger:before { content: '\42'; } /* 'B' */
.icon-dribbble:before { content: '\44'; } /* 'D' */
.icon-evernote:before { content: '\45'; } /* 'E' */
.icon-flickr:before { content: '\46'; } /* 'F' */
.icon-linkedin:before { content: '\4c'; } /* 'L' */
.icon-rss:before { content: '\52'; } /* 'R' */
.icon-twitter:before { content: '\54'; } /* 'T' */
.icon-youtube:before { content: '\55'; } /* 'U' */
.icon-vimeo:before { content: '\56'; } /* 'V' */
.icon-xing:before { content: '\58'; } /* 'X' */
.icon-yahoo:before { content: '\59'; } /* 'Y' */
.icon-amazon:before { content: '\61'; } /* 'a' */
.icon-steam:before { content: '\62'; } /* 'b' */
.icon-dropbox:before { content: '\64'; } /* 'd' */
.icon-facebook:before { content: '\66'; } /* 'f' */
.icon-github:before { content: '\67'; } /* 'g' */
.icon-lastfm:before { content: '\6c'; } /* 'l' */
.icon-tumblr:before { content: '\74'; } /* 't' */
.icon-wordpress:before { content: '\77'; } /* 'w' */
.icon-linkedin-rect:before { content: '\f31b'; } /* '?' */
.icon-instagram:before { content: '\f31f'; } /* '?' */


/* Social Icons styles for topbar
------------------------------------- */
.agent ul.social-icons,
#footer ul.social-icons,
.listing-share ul.social-icons,
#top-bar ul.social-icons {
	margin: 0;
	padding: 0;
}

.listing-share .social-icons li a,
.agent .social-icons li a,
#footer .social-icons li a,
#top-bar .social-icons li a {
	width: 45px;
	height: 45px;
	display: inline-block;
	background-color: transparent;
	position: relative;
	overflow: hidden;
	border-radius: 0;
	transform:scale(1);
	margin-right: 0;
}

.listing-share .social-icons li,
.agent .social-icons li,
#footer .social-icons li,
#top-bar .social-icons li {
	border-radius: 0;
	transform:scale(1);
	-webkit-transform:scale(1);
	-webkit-filter: blur(radius);
}


.listing-share .social-icons li a i,
.listing-share .social-icons li a:before,
.agent .social-icons li a i,
.agent .social-icons li a:before,
#footer .social-icons li a i,
#footer .social-icons li a:before,
#top-bar .social-icons li a i,
#top-bar .social-icons li a:before {
	transform:scale(1.1) translateY(calc(-50% - 2px));
	-moz-transform:scale(1.1) translateY(calc(-50% - 1px));
	-ms-transform:scale(1.1) translateY(-50%);
	left: 0;
	right: 0;
	margin: 0;
	text-align: center;
	margin-top: 55%;
}

.listing-share .social-icons li a.twitter i,
.listing-share .social-icons li a.twitter:before,
.agent .social-icons li a.twitter i,
.agent .social-icons li a.twitter:before,
#footer .social-icons li a.twitter i,
#footer .social-icons li a.twitter:before,
#top-bar .social-icons li a.twitter i,
#top-bar .social-icons li a.twitter:before { transform:scale(1.1) translateY(calc(-50% - 1px)); }


/* Header Icon Styles */
#top-bar .social-icons { float: right; }

.agent .social-icons li,
#footer .social-icons li,
#top-bar .social-icons li {
	margin: 0;
	padding: 0;
	float: left;
	line-height: 0;
}

#top-bar .social-icons li a:before,
#top-bar .social-icons li a i { font-size: 14px; color: #fff; }
#top-bar .social-icons li .twitter:before,
#top-bar .social-icons li .twitter i:before { font-size: 12px; }

#top-bar .social-icons li a:hover {
	background: #fff !important;
	border-color: transparent;
}

#top-bar .social-icons li a:hover i {
	color: #66676b;
}



/* Social Icons styles for footer
------------------------------------- */
.listing-share .social-icons li a,
.agent .social-icons li a,
#footer .social-icons li a {
	width: 38px;
	height: 40px;
	background-color: transparent;
}

.agent .social-icons,
#footer .social-icons {
	position: relative;
	left: -13px;
}

.agent .social-icons li,
#footer .social-icons li {
	margin: 0;
	padding: 0;
	float: left;
	line-height: 0;
}

.listing-share .social-icons li a:before,
.listing-share .social-icons li a i,
.agent .social-icons li a:before,
.agent .social-icons li a i,
#footer .social-icons li a:before,
#footer .social-icons li a i { font-size: 15px; color: #a0a0a0; }

.listing-share .social-icons li .twitter:before,
.listing-share .social-icons li .twitter i:before,
.listing-share .social-icons li .vimeo:before,
.listing-share .social-icons li .vimeo i:before,
.agent .social-icons li .twitter:before,
.agent .social-icons li .twitter i:before,
.agent .social-icons li .vimeo:before,
.agent .social-icons li .vimeo i:before,
#footer .social-icons li .twitter:before,
#footer .social-icons li .twitter i:before,
#footer .social-icons li .vimeo:before,
#footer .social-icons li .vimeo i:before { font-size: 13px; }

.listing-share .social-icons li a:hover,
.agent .social-icons li a:hover,
#footer .social-icons li a:hover {
	background: transparent !important;
	border-color: transparent;
}

.listing-share .social-icons li a:hover i,
.agent .social-icons li a:hover i,
#footer .social-icons li a:hover i {
	color: #66676b;
}

