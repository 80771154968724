/* ---------------------------------- */
/* Coming Soon Page
------------------------------------- */

.coming-soon-page {
   height: 100vh;
   background-repeat: no-repeat;
   background-size: cover;
   background-position: 50%;
   position: relative;
   .container {
     z-index: 15;
     position: relative;
     text-align: center;
     top: 50%;
     transform: translateY(-50%);
   }
   &:before {
     height: 100%;
     width: 100%;
     position: absolute;
     z-index: 10;
     content: "";
     background-color: rgba(26, 26, 26, 0.5);
   }
   h2, h3 {
     margin: 0;
     padding: 0;
   }
   h2 {
     font-size: 46px;
     color: #fff;
   }
   h3 {
     font-size: 34px;
     font-weight: 300;
     margin: 30px 0 30px 0;
     color: rgba(255, 255, 255, 0.9);
   }
   .main-search-input-item input {
     font-size: 16px;
     font-weight: 600;
   }
   .gray-style.main-search-input {
     max-width: 650px;
     margin: 0 auto;
   }
   .main-search-input button.button {
     font-size: 16px;
   }
 }
 
 @media (max-width: 992px) {
   .coming-soon-page {
     min-height: 100vh;
     height: auto;
     .container {
       padding-top: 50px;
       padding-bottom: 50px;
       top: auto;
       transform: translateY(0%);
     }
   }
 }
 
 /* Countdown */
 
 #countdown {
   font-weight: 600;
   float: none;
   display: inline-block;
   div {
     display: inline-block;
     margin: 0 5px;
     float: left;
     &:first-child i {
       border: none;
     }
   }
   span {
     background-color: rgba(255, 255, 255, 0.1);
     font-size: 40px;
     color: #fff;
     width: 120px;
     display: inline-block;
     padding: 32px 0;
     font-weight: 400;
     letter-spacing: -1px;
     border-radius: 3px 3px 0 0;
   }
   i {
     display: block;
     font-style: normal;
     margin: 0;
     text-transform: uppercase;
     font-size: 13px;
     padding: 8px 0;
     letter-spacing: 1px;
     color: #fff;
     border-radius: 0 0 3px 3px;
   }
 }
 
 .countdown-text {
   font-size: 24px;
   margin-top: 15px;
   line-height: 36px;
   font-weight: 300;
 }
 
 @media (max-width: 550px) {
   #countdown {
     width: 100%;
     div, span {
       width: 100%;
     }
     div {
       padding: 10px 0;
     }
   }
 }