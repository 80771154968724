
/* ---------------------------------- */
/* Testimonials
------------------------------------- */
.testimonial-box {
	background: transparent;
	border-radius: 6px;
	padding: 38px;
	padding-left: 110px;
	color: #888;
	position: relative;
	margin: 18px 0;
	box-shadow: none;
	transition: 0.4s;
	cursor: grab;
}

.testimonial-carousel .slick-slide {
	margin: 0 20px 30px 20px;
	opacity: 0.5;
	transition: 0.4s;
	pointer-events: all;
}

.testimonial-carousel .slick-slide.slick-active .testimonial-box {
	background: $primary;
	color: #fff;
}

.testimonial-carousel .slick-slide.slick-active .testimonial:before {
	color: $primary;
}

.testimonial:before {
	position: absolute;
	margin: 0 auto;
	display: block;
	width: 100%;
	text-align: center;
	bottom: -10px;
	left: 0;
	content: "\f0dd";
	font-family: "FontAwesome";
	font-size: 42px;
	color: transparent;
	background-color: transparent;
	cursor: default;
}

.testimonial:after {
	position: absolute;
	top: 47px;
	left: 42px;
	content: "\f10d";
	font-family: "FontAwesome";
	font-size: 38px;
	opacity: 0.3;
	color: #fff;
	background-color: transparent;
	cursor: default;
}

.testimonial,
.testimonial p {
	font-size: 16px;
	font-weight: 500;
	line-height: 30px;
}

.testimonial-author {
	display: block;
	width: 100%;
	padding: 20px 0 15px 0;
	text-align: center;
	margin: 0 auto;
}

.testimonial-author img {
	width: 60px;
	display:inline-block;
	text-align: center;
	height: auto;
	border-radius: 50%;
	margin: 0 auto;
}

.testimonial-author h4 {
	display: block;
	width: 100%;
	font-size: 17px;
	padding-top: 5px;
	color: #333;
	font-weight: 600;
}

.testimonial-author h4 span {
	display: block;
	color: #888;
	padding-top: 6px;
	font-weight: 400;
	font-size: 15px;
}



.testimonial-box .star-rating {
	height: 30px;
	display: inline-block;
	margin-bottom: -10px;
	margin-top: 15px;
}
