

/* ---------------------------------- */
/* Small Category Boxes
------------------------------------- */
.categories-boxes-container {
	width: calc(100% + 20px);
	left: -20px;
	position: relative;
	display: flex;
	flex-wrap: wrap;
}

.category-small-box {
	float: left;
	background-color: #f2f2f2;
	border-radius: 4px;
	margin: 20px 0 0 20px;
	padding: 22px 20px;
	text-align: center;
	color: #777;
	transition: 0.4s;
	transform: scale(1);
	vertical-align: top;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

/* Hover Animation */
.category-small-box:hover {
	background-color: #66676b;
	color: #fff;
	-webkit-transform: scale(1.06);
	-moz-transform: scale(1.1);
}

.category-small-box:hover h4 {
	font-size: 17px;
}

.category-small-box h4 {
	font-size: 16px;
	color: #333;
	transition: 0.4s;
	line-height: 22px;
	margin-top: 8px;
}

.category-small-box i {
	font-size: 42px;
	height: 40px;
	display: inline-block;
	margin-top: 20px;
}

.category-small-box:hover h4 { color: #fff; }


/* Media Queries For Small Category Boxes */
@media (max-width: 768px) {
	.category-small-box { width: calc(100% * (1/2) - 21px); }
}
@media (min-width: 768px) {
	.category-small-box { width: calc(100% * (1/3) - 21px); }
}
@media (min-width: 992px) {
	.category-small-box { width: calc(100% * (1/3) - 21px); }
}
@media (min-width: 1240px) {
	.category-small-box { width: calc(100% * (1/6) - 21px); }
}
