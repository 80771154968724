//colors

#titlebar {
	position: relative;
	padding: 70px 0;
	padding: 8rem 0;
	background: url(../../../assets/images/title-bar.jpg);
	background-size: cover;
	background-position: center center;
   pointer-events: none;
   color: #fff;
	.container {
		z-index: 99;
	}

	h2 {
	 	color: #fff;
	 }
	#breadcrumbs span {
		font-size: 13px;
	}
	&.photo-bg #breadcrumbs ul li {
		color: #ffffff;
		a {
			color: #ffffff;
		}
		&:before {
			color: #ffffff;
		}
	}
}
.title-bar-inner {
	position: relative;
	z-index: 99;
}
#breadcrumbs {
	position: absolute;
	right: 15px;
	display: inline-block;
	font-size: 15px;
	font-weight: 400;
	color: #fff;
	top: 50%;
	//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
	transform: translateY(-50%);
	ul {
		margin: 0;
		li {
			display: inline-block;
			list-style: none;
			margin: 0 0 0 10px;
			a {
				
				transition: all 0.2s ease-in-out;
				color: #ffffff;
				&:hover {
					color: #fff;
				}
			}
			&:before {
				content: "\f105";
				font-family: FontAwesome;
				color: #fff;
				font-size: 14px;
				margin-right: 15px;
			}
			&:first-child:before {
				display: none;
			}
		}
	}
}
.title-overlay {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background:rgba(0, 0, 0, 0.6);
}
