/* ---------------------------------- */
@media (min-width: 992px) {
   /* Avatar */
   #navigation {
     position: relative;
     display: block;
     ul {
       list-style: none;
       position: relative;
       float: left;
       margin: 0;
       padding: 0;
       a {
         display: block;
         text-decoration: none;
       }
       li {
         position: relative;
         float: left;
         margin: 0;
         padding: 0;
         ul {
           opacity: 0;
           visibility: hidden;
           position: absolute;
           top: 100%;
           left: 0;
           padding: 0;
           z-index: 99999;
         }
       }
       ul {
         li {
           float: none;
         }
         ul {
           top: 0;
           left: 100%;
           margin: 0;
         }
         margin: 10px 0 0 15px;
         &:before {
           content: "";
           position: absolute;
           left: 0;
           top: -10px;
           background: rgba(255, 255, 255, 0);
           width: 100%;
           height: 10px;
         }
         ul:after {
           content: "";
           position: absolute;
           width: 10px;
           height: 100%;
           left: -10px;
           top: 0;
           opacity: 0;
         }
       }
       li {
         &:hover > ul {
           opacity: 1;
           visibility: visible;
         }
         a:after {
           font-family: "FontAwesome";
           opacity: 0.5;
         }
       }
       ul li a:after {
         font-family: "FontAwesome";
         opacity: 0.5;
       }
       li a:after {
         content: '\f107';
         padding-left: 7px;
       }
       ul li a:after {
         content: '\f105';
         position: absolute;
         right: 15px;
         top: 7px;
       }
       li a:only-child:after {
         content: '';
         padding: 0;
       }
       ul {
         li {
           a:only-child:after {
             content: '';
           }
           border-radius: 4px;
           &:last-child {
             border-bottom: none;
           }
         }
         background-color: #fff;
         box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
         border-radius: 4px;
         font-size: 15px;
       }
       li:hover ul a, ul a {
         line-height: 27px !important;
         padding: 5px 40px 5px 15px !important;
         line-height: 24px !important;
       }
     }
   }
   body #navigation ul ul {
     padding: 12px 10px;
     box-sizing: border-box;
   }
   #navigation {
     ul {
       ul {
         li {
           width: 180px;
           &:hover {
             border-radius: 0;
           }
           &:first-child {
             border-radius: 4px 4px 0 0;
           }
           &:last-child {
             border-radius: 0 0 4px 4px;
           }
         }
         opacity: 0;
         visibility: hidden;
         transition: all 0.25s;
         transform: translate3d(0, 15px, 0);
       }
       li:hover > ul {
         opacity: 1;
         visibility: visible;
         transform: translate3d(0, 0, 0);
       }
     }
     &.style-1 {
       .current {
         background-color: transparent;
        //  color: #fff;
         &:after {
           opacity: 1;
         }
       }
       margin-top: 1px;
       ul {
         li {
           transition: all 0.25s;
           a {
             color: #333;
             cursor: pointer;
           }
           &:hover a {
            //  color: rgba(255, 255, 255, 0.65);
             color: #111;
           }
         }
         ul li {
           a {
             color: #444 !important;
             text-transform: capitalize;
           }
           opacity: 0.8;
           &:hover {
             opacity: 1;
           }
         }
         a {
           line-height: 32px;
           padding: 5px 15px;
           cursor: pointer;
         }
         li {
           margin: 0 5px;
         }
         ul li {
           margin: 0;
           border-radius: 0;
           &:first-child {
             border-radius: 4px 4px 0 0;
           }
           &:last-child {
             border-radius: 0 0 4px 4px;
           }
         }
       }
     }
     ul ul li {
       a:after {
         opacity: 0.5;
         transition: all 0.25s;
         transform: translateX(-5px);
       }
       &:hover a:after {
         opacity: 1;
         transform: translateX(0);
       }
     }
   }
   #navigation {
     ul li a i {
       font-size: 14px;
       position: relative;
       top: 0;
       padding-right: 5px;
     }
   }
  

 
  
 }

 #responsive a {
	cursor: pointer;
}