/* ------------------------------------------------------------------- */
/*  05. Script Styles
---------------------------------------------------------------------- */

/* ---------------------------------- */
/* Google Maps
------------------------------------- */
.infoBox {
	border-radius: 4px;
}

.map-box {
	background-color: #fff;
	margin-bottom: 0px;
	border-radius: 4px;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
	position: relative;
}

.map-box ::after{
	content: '' !important;
}

.map-box .listing-img-container img {
	width: 100%;
	border-radius: 4px 4px 0 0;
}

.map-box h4 {
	margin: 0;
	padding: 0;
}

.map-box h4 a {
	padding: 0 0 2px 0;
	font-size: 17px;
	line-height: 25px;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	display: inline-block;
}

.listing-img-container {
	position: relative;
	height: 100%;
	display: block;
}

.map-box h4 a:hover {
	color: #66676b;
}

.map-box p {
	padding: 0;
	line-height: 25px;
	margin: 2px 0 0 0;
	font-size: 14px;
}

.map-box ul.listing-details {
	padding: 0;
	font-size: 14px;
	display: none;
}

.map-box .listing-title {
	padding: 16px 22px;
}


.map-box .listing-img-content {
	padding: 18px 22px;
}


.map-box .listing-img-content span {
	font-size: 21px;
}

.map-box .listing-img-content .listing-price i {
	font-size: 15px;
	margin: -7px 0 0 0;
}


.infoBox>img {
	position: absolute !important;
	right: 0px;
	top: 0px;
}

.map-box .listing-img-container::before {
	content: "";
	position: absolute;
	height: 100%;
	width: 100%;
	display: block;
	background: linear-gradient(to top, rgba(35, 35, 37, 0.85) 0%, rgba(35, 35, 37, 0.40) 35%, rgba(22, 22, 23, 0) 60%, rgba(0, 0, 0, 0) 100%);
	background-color: rgba(35, 35, 37, 0.2);
}

.map-box .listing-item-content {
	position: absolute;
	bottom: 20px;
	left: 0;
	padding: 0 24px;
	width: 100%;
	z-index: 50;
	box-sizing: border-box;
	background: transparent;
}

.map-box .listing-item-content h3 {
	color: #fff;
	font-size: 18px;
	padding: 0 0 2px 0;
	font-weight: 500;
	margin: 0;
	line-height: 27px;
}

.map-box .listing-item-content span {
	font-size: 15px;
	font-weight: 300;
	display: inline-block;
	color: rgba(255, 255, 255, 0.7);
}


/* Close Button */
.infoBox-close {
	position: absolute;
	top: 10px;
	right: 10px;
	display: inline-block;
	z-index: 999;
	text-align: center;
	line-height: 29px;
	cursor: pointer;
	font-size: 15px;
	font-weight: 500;
	height: 29px;
	width: 29px;
	background-color: #fff;
	color: #333;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	font-family: "FontAwesome";
	border-radius: 50%;
	-webkit-text-stroke: 1px #fff;
}

.infoBox-close:hover {
	color: #fff;
	background-color: #66676b;
	-webkit-text-stroke: 1px #66676b;
}


/* Read More Icon */
.map-box-icon {
	height: 38px;
	width: 38px;
	position: absolute;
	bottom: 0;
	right: 0;
	background-color: #3685cf;
	display: block;
	color: #fff;
	font-size: 12px;
	z-index: 101;
	text-align: center;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	-webkit-transform: translateX(50%);
	-ms-transform: translateX(50%);
	transform: translateX(50%);
	opacity: 0;
	visibility: hidden;
}

.map-box-icon:before {
	content: "\f064";
	font-family: "FontAwesome";
	position: relative;
	top: 7px;
	display: block;
}

.map-box-image:hover .map-box-icon {
	opacity: 1;
	visibility: visible;
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateX(0);
}

.map-box-image {
	position: relative;
	overflow: hidden;
	display: block;
}

.infoBox {
	-webkit-animation: fadeIn 0.9s;
	animation: fadeIn 0.9s;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

/* Standard syntax */
@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}




#map {
	height: 100%;
	width: 100%;
	margin: 0;
	z-index: 990;
}

#map-container {
	position: relative;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 990;
	margin-bottom: 60px;
}

.gmnoprint {
	margin: 10px;
}


#map-container.fullwidth-home-map {
	height: 620px;
	margin-bottom: 0;
	margin-top: 140px;
	
}
@media only screen and (max-width: 550px) {
	#map-container.fullwidth-home-map {

		width: 100%;
	
		
	}
}




#map-container.fullwidth-home-map .main-search-inner {
	position: absolute;
	display: block;
	bottom: 120px !important;
	top: auto;
	transform: none;
	padding-bottom: 0;
	z-index: 9999;
	margin-top: 100px;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
}

#map-container.fullwidth-home-map .main-search-input {
	margin-top: 0;
}



/* Cluster styles */
.cluster img {
	display: none
}

.cluster-visible {
	text-align: center;
	font-size: 15px !important;
	color: #fff !important;
	font-weight: 500;
	border-radius: 50%;
	width: 36px !important;
	height: 36px !important;
	line-height: 36px !important;
	background-color: #66676b;
}

.cluster-visible:before {
	border: 7px solid #66676b;
	opacity: 0.2;
	box-shadow: inset 0 0 0 4px #66676b;
	content: '';
	position: absolute;
	border-radius: 50%;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	animation: cluster-animation 2.5s infinite;
}

@keyframes cluster-animation {

	0%,
	100% {
		transform: scale(1.3) rotate(0deg)
	}

	50% {
		transform: scale(1.5) rotate(90deg)
	}
}

/* Prev & Next Buttons
------------------------------------- */
#mapnav-buttons {
	position: absolute;
	transform: translate(0, 0);
	z-index: 999;
	font-size: 14px;
	display: inline-block;
	bottom: 20px;
	right: 20px;
	list-style: none;
	padding: 0;
}

#mapnav-buttons.top {
	top: 20px;
	right: 20px;
	bottom: auto;
}

#mapnav-buttons li {
	float: left;
	margin-left: 4px;
	line-height: 20px;
}

#streetView,
#geoLocation,
#scrollEnabling,
#mapnav-buttons a {
	color: #333;
	background-color: #fff;
	padding: 7px 18px;
	padding-top: 9px;
	font-weight: 500;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-transition: all 0.2s ease-in-out;
	box-sizing: border-box;
	display: inline-block;
	border-radius: 50px;
	box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.2);
}

#geoLocation:hover,
#streetView:hover,
#scrollEnabling:hover,
#scrollEnabling.enabled,
#mapnav-buttons a:hover {
	background-color: #66676b;
	color: #fff;
}

#prevpoint:before,
#nextpoint:after {
	font-family: "FontAwesome";
	position: relative;
	font-weight: 500;
	margin: 0 0 0 6px;
	font-size: 17px;
	top: 0px;
	line-height: 1px;
}

#prevpoint:before {
	content: "\f104";
	margin: 0 6px 0 0;
}

#nextpoint:after {
	content: "\f105";
	margin: 0 0 0 6px;
}

#streetView,
#geoLocation,
#scrollEnabling {
	position: absolute;
	top: 20px;
	right: 20px;
	z-index: 999;
	font-size: 13px;
	line-height: 21px;
}

#streetView:before,
#geoLocation:before,
#scrollEnabling:before {
	content: "\e015";
	font-family: "simple-line-icons";
	position: relative;
	top: 2px;
	margin: 0 6px 0 0;
	font-size: 15px;
	line-height: 1px;
}

#scrollEnabling:before {
	margin-left: -3px;
}

#streetView:before {
	content: "\f21d";
	font-family: "FontAwesome";
	font-size: 16px;
	top: 1px;
	margin-right: 8px;
}


#geoLocation {
	right: auto;
	left: 20px;
	padding: 8px 11px;
}

#geoLocation:before {
	content: "\f192";
	font-family: "FontAwesome";
	font-size: 16px;
	margin: 0;
	top: 2px;
}

.agm-info-window-content {

	//    img{
	//       width: auto;
	//       max-width: 100% !important;
	//       height: auto;
	//    }
	//    .listing-item-content{
	//       padding: 1rem;
	//    }
	// }


	// .gm-style .gm-style-iw {
	//    width: 100%;
	//    overflow: hidden;
}

.infoBox {
	width: 240px;
}

body .gm-style * {
	background: transparent !important;
	box-shadow: none !important;

}

body .gm-ui-hover-effect {
	position: absolute !important;
	right: 45px !important;
	top: 19px !important;
	background-image: url(../../images/download-removebg-preview.png);
}

.infoBox::after {
	left: 46%;
	//	bottom: -16px;
}

body .infoBox {
	box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
	padding: 10px;
	background: #fff !important;
}

.agm-info-window-content {
	margin-bottom: 1rem;
	max-height: 200px;
	max-width: 300px;
}

.agm-map-container-inner img {
	box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);

	border-radius: 6px;
	width: 30px;
}

// .agm-map-container-inner{
//    img[src="assets/images/breakfast.png"],img[src="assets/images/beer.png"],img[src="assets/images/burger.png"],img[src="assets/images/popcorn.png"]{
//       background: #fff;
//       position: relative;
//       padding: 5px !important;

//       width: 40px !important;

//       height: 40px !important;

//       opacity: 0.7 !important;
//       transition: all 0.3s ease-in-out 0s;

//       &:after{
//          top: 100%;
//          left: 50%;
//          border: solid transparent;
//          content: " ";
//          height: 0;
//          width: 0;
//          position: absolute;
//          pointer-events: none;
//          border-color: rgba(255, 255, 255, 0);
//          border-top-color: #fff;
//          border-width: 8px;
//          margin-left: -8px;
//       }
//    }

// }
// body .agm-map-container-inner{
//    img[src="assets/images/breakfast.png"],img[src="assets/images/beer.png"],img[src="assets/images/burger.png"],img[src="assets/images/popcorn.png"]{
//       &:hover{
//          opacity: 1 !important;
//       }
//    }
// }